import React from 'react';

import { Col, Row } from 'reactstrap';
import { AnimateIn, Flex, FlexRow } from '../components';
import SideDiamond from '../components/SideDiamond/SideDiamond';
import Markdown from '../components/Markdown/Markdown';

import safetyAssistanceDataJson from '../data/safetyAssistance.json';
import SidePageLayout from '../components/SidePageLayout/SidePageLayout';
import PlaneIcon from '../components/Icons/PlaneIcon';

const DiamondIcon = <PlaneIcon width={40} />;

export const SafetyAssistancePageInternal = (safetyAssistanceData: typeof safetyAssistanceDataJson) => {
  return (
    <SidePageLayout
      title="Safety Assistance Program"
      type="aviation"
      Header={(
        <FlexRow className="ml-xl-5 px-3" fill alignCenter overflowHidden>
          <div>
            <img className="img-fluid" alt="Aviation Safety Solutions" src={safetyAssistanceData.headerImg} />
          </div>
        </FlexRow>
      )}
      includeAirplaneMarquee
      callToActionTagline={safetyAssistanceData.callToActionTagline}
    >
      <Row>
        <Col className="d-none d-md-block" md={4}>
          <Flex center className="side-affix">
            <SideDiamond label={<span>Safety<br/>Assistance<br/>Program</span>} icon={DiamondIcon} />
          </Flex>
        </Col>
        <Col md={7} className="side-page-top-spacer">
          <AnimateIn tag="section" className="px-2 pt-5 body-bg">
            <Markdown source={safetyAssistanceData.mainMarkdown} />
          </AnimateIn>
        </Col>
      </Row>
    </SidePageLayout>
  );
};

export default () => <SafetyAssistancePageInternal {...safetyAssistanceDataJson} />;
